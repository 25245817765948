<template>
  <v-container style="margin-top: 75px;">
    <v-card>
      <v-data-table :headers="headers" :items="users" :server-items-length="totalItems" :options.sync="options"
        :loading="loading" :footer-props="footerProps" class="elevation-1">

        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>用户列表</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on" @click="exportData()">
                  <v-icon>mdi-download</v-icon>
                </v-btn>
              </template>
              <span>导出用户数据</span>
            </v-tooltip>
          </v-toolbar>
        </template>

        <template v-slot:item.action="{ item }">
          <!-- 使用 v-tooltip 添加悬停提示 -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon small v-on="on" @click="viewLatestVerificationCode(item.phone)">
                mdi-message-text-outline <!-- 使用短信图标 -->
              </v-icon>
            </template>
            <span>查看最近一次验证码</span> <!-- 提示信息 -->
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title class="headline">最近一次验证码</v-card-title>
        <v-card-text>
          <!-- 这里显示具体的数据 -->
          <div v-if="smsCode">
            <p>验证码: {{ smsCode }}</p>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">关闭</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import api from "../../api/api";
import axios from "axios";
export default {
  data() {
    return {
      smsCode: '',
      dialog: false,
      headers: [
        { text: '手机号码', align: 'start', sortable: false, value: 'phone' },
        { text: '登录IP', value: 'ip', sortable: false },
        { text: '创建时间', value: 'createTime', sortable: false },
        { text: '上一次登录时间', value: 'lastUpdateTime', sortable: false },
        { text: '操作', value: 'action', sortable: false },
      ],
      users: [],
      options: {
        page: 1,
        itemsPerPage: 5,
      },
      loading: false,
      footerProps: {
        itemsPerPageOptions: [5, 10, 15],
        showFirstLastPage: true,
        showCurrentPage: true,
        showTotalItems: true
      }
    };
  },
  watch: {
    options: {
      handler() {
        this.fetchItems();
      },
      deep: true,
    },
  },
  created() {
    this.fetchItems();
  },
  methods: {
    exportData() {
      const key = sessionStorage.getItem("key");
      axios({
        url: 'http://120.26.117.241:8055/msn/manager/export',
        method: 'GET',
        responseType: 'blob', // 重要：设置响应类型为 blob
        headers: {
          key: key
        }
      }).then((response) => {
        // 从响应头中获取文件名
        const contentDisposition = response.headers['content-disposition'];
        let fileName = '用户信息.xlsx'; // 默认文件名
        if (contentDisposition) {
          const fileNameMatch = contentDisposition.match(/filename\*?=(?:UTF-8'')?([^;]+)/);
          if (fileNameMatch && fileNameMatch.length === 2) {
            fileName = decodeURIComponent(fileNameMatch[1]);
          }
        }

        // 创建一个可下载的链接
        const url = window.URL.createObjectURL(new Blob([response.data], { type: response.data.type }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName); // 使用从响应头获取的文件名

        // 模拟点击链接来下载文件
        document.body.appendChild(link);
        link.click();

        // 清理DOM和释放资源
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      }).catch((error) => {
        console.error('下载失败:', error);
      });
    },
    viewLatestVerificationCode(phone) {
      api.get('manager/sms?phone=' + phone).then(response => {
        if (response.status == 200) {
          this.smsCode = response.data.data;
          if (this.smsCode == null || this.smsCode == '') {
            this.smsCode = '无数据';
          }
          this.dialog = true;
        }
      })
      console.log('待实现');
    },
    fetchItems() {
      this.loading = true;
      api.get(`manager/users?pageNum=${this.options.page}&pageSize=${this.options.itemsPerPage}`).then(response => {
        this.loading = false;
        if (response.status === 200) {
          this.totalItems = response.data.data.total;
          this.users = response.data.data.records;
          for (const user of this.users) {
            user.createTime = this.formatDate(user.createTime);
            user.lastUpdateTime = this.formatDate(user.lastUpdateTime);
          }
          console.log(this.users)
        }
      }).catch(() => {
        this.loading = false;
      });
    },
    formatDate(timestamp) {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0'); // getMonth() 返回的月份是从 0 开始的
      const day = date.getDate().toString().padStart(2, '0');
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const seconds = date.getSeconds().toString().padStart(2, '0');
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
  },
};
</script>

<style scoped></style>
